const yearInterestRatePairUK = {
        1975: 10.76,
        1976: 11.73,
        1977: 8.45,
        1978: 9.13,
        1979: 13.75,
        1980: 16.31,
        1981: 13.16,
        1982: 11.96,
        1983: 9.86,
        1984: 9.67,
        1985: 12.06,
        1986: 10.74,
        1987: 9.6,
        1988: 9.95,
        1989: 13.68,
        1990: 14.65,
        1991: 11.56,
        1992: 9.43,
        1993: 5.9,
        1994: 5.34,
        1995: 6.57,
        1996: 5.89,
        1997: 6.55,
        1998: 7.23,
        1999: 5.34,
        2000: 5.96,
        2001: 5.12,
        2002: 4,
        2003: 3.69,
        2004: 4.38,
        2005: 4.65,
        2006: 4.64,
        2007: 5.51,
        2008: 4.68,
        2009: 0.64,
        2010: 0.5,
        2011: 0.5,
        2012: 0.5,
        2013: 0.5,
        2014: 0.5,
        2015: 0.5,
        2016: 0.4,
        2017: 0.29,
        2018: 0.6,
        2019: 0.75,
        2020: 0.23,
        2021: 0.11,
        2022: 1.46,
        2023: 4.51
    };

export default yearInterestRatePairUK
