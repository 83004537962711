import React from "react";

const HeadBanner = () => {
    return (
        <div className="headBanner">
          <div className="logoContainer">
          <div className = "logoWriting">
            HistoricalSavingsCalculator.com
          </div>
          </div>
        </div>
    );
};

export default HeadBanner;
