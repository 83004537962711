import React from "react";
import "./styles.css";

const OrSymbol = () => {

    console.log('or');
    return (
        <div className="orContainer">
            OR
        </div>
    )

}

export default OrSymbol
